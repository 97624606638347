  import React, { useState, useMemo } from 'react';
  import { useParams } from 'react-router-dom';
  import {
    Box,
    Select,
    MenuItem,
    InputLabel,
    useTheme,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    IconButton,
    Modal,
  } from '@mui/material';
  import { DataGrid } from '@mui/x-data-grid';
  import { Visibility ,Edit, Delete } from '@mui/icons-material';
  import Header from '../../components/Header';
  import { tokens } from '../../theme';
  import { format, parseISO, isValid } from 'date-fns';
  import useFetchData from '../Hook/hookEgresos';
  import TotalImportes from '../totalImportes/TotalImportes';
  import RegistroEgresosDialog from './RegistroEgresosDial';
  import EditarEgresoForm from './EditarEgresoForm';
  import EgresosImagenDialog from './EgresosImagenDialog';

  const Egresos = () => {
    const theme = useTheme();
    const { id_empresa, id_edificio } = useParams();
    const colors = tokens(theme.palette.mode);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedType, setSelectedType] = useState('all');
    const [openDialog, setOpenDialog] = useState(false);
    const [editRow, setEditRow] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: 'info', message: '' });
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [selectedEgreso, setSelectedEgreso] = useState(null);

    const {
      loading,
      administracionData,
      serviciosData,
      pagosPersonalData, 
      gastosBancariosData,
      gastosVariosData,
      mantenimientosData,
      fetchData,
    } = useFetchData(selectedMonth, selectedYear, id_empresa, id_edificio);

    const generateUniqueId = (prefix, id) => `${prefix}-${id || Math.random().toString(36).substr(2, 9)}`;

    const egresosData = useMemo(() => {
      const combinedData = [
        ...(administracionData || []).map((item) => ({ ...item, id: generateUniqueId("admin", item.id) })),
        ...(serviciosData || []).map((item) => ({ ...item, id: generateUniqueId("servicio", item.id) })),
        ...(pagosPersonalData || []).map((item) => ({ ...item, id: generateUniqueId("pago-personal", item.id) })),
        ...(gastosBancariosData || []).map((item) => ({ ...item, id: generateUniqueId("gasto-bancario", item.id) })),
        ...(gastosVariosData || []).map((item) => ({ ...item, id: generateUniqueId("gasto-varios", item.id) })),
        ...(mantenimientosData || []).map((item) => ({ ...item, id: generateUniqueId("mantenimiento", item.id) })),
      ];
    
      return combinedData.filter((item) => {
        const date = parseISO(item.fecha);
        const matchesType = selectedType === "all" || item.tipo === selectedType;
        return (
          isValid(date) &&
          date.getMonth() + 1 === selectedMonth &&
          date.getFullYear() === selectedYear &&
          matchesType
        );
      });
    }, [
      administracionData,
      serviciosData,
      pagosPersonalData,
      gastosBancariosData,
      gastosVariosData,
      mantenimientosData,
      selectedMonth,
      selectedYear,
      selectedType,
    ]);
    

    const handleDelete = (id) => {
      console.log("Eliminar egreso con ID:", id);
      setAlert({ open: true, severity: 'info', message: 'Funcionalidad de eliminación en desarrollo' });
    };

    const handleEdit = (row) => {
      setEditRow(row);
      setEditModalOpen(true);
    };

    const handleEditSubmit = (updatedData) => {
      console.log("Datos editados:", updatedData);
      fetchData();
      setAlert({ open: true, severity: 'success', message: 'Egreso editado exitosamente' });
      setEditModalOpen(false);
    };

    const handleDialogClose = () => {
      setOpenDialog(false);
      setEditRow(null);
    };

    const handleAddSuccess = () => {
      fetchData();
      setAlert({ open: true, severity: 'success', message: 'Egreso registrado exitosamente' });
    };

    // Manejar la apertura del modal
    const handleViewImage = (row) => {
  setSelectedEgreso(row);
  setImageDialogOpen(true);
};

    // Manejar el cierre del modal
    const handleImageDialogClose = () => {
      setImageDialogOpen(false);
      setSelectedEgreso(null);
    };

    const handleAlertClose = () => setAlert({ ...alert, open: false });

    const columns = [
      { field: 'fecha', headerName: 'Fecha', flex: 1, minWidth: 180,
        valueFormatter: ({ value }) => isValid(parseISO(value)) ? format(parseISO(value), 'dd/MM/yyyy') : ''
      },
      { field: 'tipo', headerName: 'Tipo', flex: 1, minWidth: 150 },
      { field: 'monto', headerName: 'Monto', flex: 1, minWidth: 110 },
      { field: 'concepto', headerName: 'Concepto', flex: 1, minWidth: 150 },
      {
        field: 'acciones',
        headerName: 'Acciones',
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" gap="10px">
        <IconButton onClick={() => handleViewImage(params.row)}>
          <Visibility style={{ color: colors.greenAccent[500] }} />
        </IconButton>
        <IconButton onClick={() => handleEdit(params.row)}>
          <Edit style={{ color: colors.blueAccent[500] }} />
        </IconButton>
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <Delete style={{ color: colors.redAccent[500] }} />
        </IconButton>
      </Box>
        ),
      },
    ];

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box m="20px">
        <Header title="Egresos" />
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {/* Filtros */}
          <Box display="flex" gap="1rem" alignItems="center">
            <Box display="flex" gap="0.5rem" alignItems="center">
              <InputLabel>MES:</InputLabel>
              <Select
                value={selectedMonth}
                onChange={(event) => setSelectedMonth(parseInt(event.target.value))}
                size="small"
              >
                {Array.from({ length: 12 }, (_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {new Date(2000, index).toLocaleString('default', { month: 'long' })}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="flex" gap="0.5rem" alignItems="center">
              <InputLabel>AÑO:</InputLabel>
              <Select
                value={selectedYear}
                onChange={(event) => setSelectedYear(parseInt(event.target.value))}
                size="small"
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <MenuItem key={index} value={new Date().getFullYear() - index}>
                    {new Date().getFullYear() - index}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="flex" gap="0.5rem" alignItems="center">
              <InputLabel>TIPO:</InputLabel>
              <Select
                value={selectedType}
                onChange={(event) => setSelectedType(event.target.value)}
                size="small"
              >
                <MenuItem value="all">Todos</MenuItem>
                <MenuItem value="Administración">Administración</MenuItem>
                <MenuItem value="Servicios Públicos">Servicios Públicos</MenuItem>
                <MenuItem value="Personal">Personal</MenuItem>
                <MenuItem value="Pagos Personal">Pagos Personal</MenuItem>
                <MenuItem value="Gastos Bancarios">Gastos Bancarios</MenuItem>
                <MenuItem value="Gastos Varios">Gastos Varios</MenuItem>
                <MenuItem value="Mantenimiento">Mantenimiento</MenuItem>
              </Select>
            </Box>
            <TotalImportes filteredData={egresosData} colors={colors} />
          </Box>

          {/* Botón para abrir el modal */}
          <Button
            variant="contained"
            style={{ backgroundColor: colors.blueAccent[700] }}
            onClick={() => setOpenDialog(true)}
          >
            Registrar
          </Button>
        </Box>

        {/* Tabla */}
        <Box
          m="10px 0 0 0"
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "14px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
              fontSize: "13px",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={egresosData}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
            noRowsOverlay={() => <Box m={2}>No hay egresos disponibles para mostrar</Box>}
          />
        </Box>

        <EgresosImagenDialog
          open={imageDialogOpen}
          onClose={handleImageDialogClose}
          egreso={selectedEgreso}
          onUploadSuccess={(url) => {
            console.log('Imagen subida con éxito:', url);
            // Actualiza la tabla o realiza cualquier otra acción necesaria
            fetchData();
          }}
        />


        {/* Modal de registro */}
        <RegistroEgresosDialog
          open={openDialog}
          onClose={handleDialogClose}
          id_empresa={id_empresa}
          id_edificio={id_edificio}
          onAddSuccess={handleAddSuccess}
          setAlert={setAlert}
        />

        {/* Modal de edición */}
        <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <Box
            p={4}
            bgcolor="background.paper"
            boxShadow={24}
            mx="auto"
            my="10%"
            borderRadius={2}
            width="50%"
          >
            <EditarEgresoForm
              rowData={editRow}
              onClose={() => setEditModalOpen(false)}
              onSubmit={handleEditSubmit}
            />
          </Box>
        </Modal>

        {/* Snackbar */}
        <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  export default Egresos;
