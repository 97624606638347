import React, { useEffect, useState } from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import axios from 'axios';

const RegistroCuentaBancaria = ({
  tipoCuentaSeleccionado,
  setTipoCuentaSeleccionado,
  nombreBanco,
  setNombreBanco,
  numeroCuenta,
  setNumeroCuenta,
  numeroCuentaCci,
  setNumeroCuentaCci,
}) => {
  const [tiposCuenta, setTiposCuenta] = useState([
    { id_tipo_cuenta: 1, nombre_tipo_cuenta: "Cuenta Ordinaria" },
    { id_tipo_cuenta: 2, nombre_tipo_cuenta: "Cuenta Recaudadora" },
  ]);
  const [bancos, setBancos] = useState([]);
  const [loadingTiposCuenta, setLoadingTiposCuenta] = useState(false);
  const [loadingBancos, setLoadingBancos] = useState(true);

  useEffect(() => {
    const fetchBancos = async () => {
      try {
        // Opcional: Puedes agregar esta lista manualmente o cargarla dinámicamente desde una API
        const bancosDisponibles = [
          "Banco de Crédito del Perú (BCP)",
          "Banco Continental (BBVA)",
          "Interbank",
          "Scotiabank",
          "Banco de la Nación",
          "Mi Banco",
          "BanBif",
        ];
        setBancos(bancosDisponibles);
      } catch (error) {
        console.error("Error al obtener los bancos:", error);
      } finally {
        setLoadingBancos(false);
      }
    };

    fetchBancos();
  }, []);

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Configuración de la Cuenta Bancaria
      </Typography>

      {/* Tipo de Cuenta */}
      <TextField
        select
        label="Tipo de Cuenta"
        value={tipoCuentaSeleccionado || ""}
        onChange={(e) => setTipoCuentaSeleccionado(e.target.value)}
        fullWidth
        margin="normal"
        error={!tipoCuentaSeleccionado && !loadingTiposCuenta}
        helperText={!tipoCuentaSeleccionado && !loadingTiposCuenta ? "Selecciona un tipo de cuenta" : ""}
      >
        {loadingTiposCuenta ? (
          <MenuItem disabled>Cargando tipos de cuenta...</MenuItem>
        ) : (
          tiposCuenta.map((tipo) => (
            <MenuItem key={tipo.id_tipo_cuenta} value={tipo.id_tipo_cuenta}>
              {tipo.nombre_tipo_cuenta}
            </MenuItem>
          ))
        )}
      </TextField>

      {/* Nombre del Banco */}
      <TextField
        select
        label="Nombre del Banco"
        value={nombreBanco || ""}
        onChange={(e) => setNombreBanco(e.target.value)}
        fullWidth
        margin="normal"
        error={!nombreBanco && !loadingBancos}
        helperText={!nombreBanco && !loadingBancos ? "Selecciona un banco" : ""}
      >
        {loadingBancos ? (
          <MenuItem disabled>Cargando bancos...</MenuItem>
        ) : (
          bancos.map((banco, index) => (
            <MenuItem key={index} value={banco}>
              {banco}
            </MenuItem>
          ))
        )}
      </TextField>

      {/* Número de Cuenta */}
      <TextField
        label="Número de Cuenta"
        value={numeroCuenta || ""}
        onChange={(e) => setNumeroCuenta(e.target.value)}
        fullWidth
        margin="normal"
        error={!numeroCuenta && !loadingTiposCuenta}
        helperText={!numeroCuenta && !loadingTiposCuenta ? "Introduce el número de cuenta" : ""}
      />

      {/* Número de Cuenta CCI */}
      <TextField
        label="Número de Cuenta CCI"
        value={numeroCuentaCci || ""}
        onChange={(e) => setNumeroCuentaCci(e.target.value)}
        fullWidth
        margin="normal"
        error={!numeroCuentaCci && !loadingTiposCuenta}
        helperText={!numeroCuentaCci && !loadingTiposCuenta ? "Introduce el número de cuenta CCI" : ""}
      />
    </Box>
  );
};

export default RegistroCuentaBancaria;
