import { useState, useRef } from 'react';
import axios from 'axios';

const useStep2 = (id_empresa, id_edificio) => {
  const [departamentos, setDepartamentos] = useState([]);
  const hotTableRefAsignacion = useRef(null);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autorización no encontrado.');
    }
    return { Authorization: `Bearer ${token}` };
  };

  const fetchDepartamentos = async () => {
    try {
      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
        { headers: getAuthHeaders() }
      );
      if (response.status === 200) {
        setDepartamentos(response.data);
      } else {
        console.error('Error al obtener los departamentos:', response);
      }
    } catch (error) {
      console.error('Error al obtener los departamentos:', error.response?.data || error.message);
    }
  };

  const saveAsignacion = async () => {
    if (!hotTableRefAsignacion.current || !hotTableRefAsignacion.current.hotInstance) {
      console.error('Handsontable instance is not ready');
      return false;
    }

    const dataToSave = hotTableRefAsignacion.current.hotInstance.getData();
    const departamentosData = [];
    const propietariosData = [];
    const cocherasData = [];
    const depositosData = [];
    const asignacionesData = [];

    try {
      // Paso 1: Preparar los datos para cada tabla
      for (let row of dataToSave) {
        if (!row[0] || !row[1]) continue;

        // Departamento
        const departamento = {
          id_edificio,
          id_empresa,
          numero_departamento: parseInt(row[0]),
          piso_departamento: 1,
          vista_departamento: 'Vista Desconocida',
          tipo_departamento: 'Tipo Desconocido',
          tipo_uso: 'Uso Desconocido',
          area: 0.0,
          numero_habitaciones: 1,
          numero_banios: 1,
          estado: true,
        };
        departamentosData.push(departamento);

        // Propietario
        const propietario = {
          id_edificio,
          id_empresa,
          nombre_propietario: row[1],
          apellido_propietario: row[2] || 'Apellido Desconocido',
          email_propietario: row[3] || 'email@desconocido.com',
          celular_propietario: row[4] || '000000000',
          tipo_propietario: 'Propietario',
          fecha_nacimiento: '1990-01-01',
          genero_propietario: 'Masculino',
          estado: true,
        };
        propietariosData.push(propietario);

        // Cochera
        const cochera = {
          id_edificio,
          id_empresa,
          numero_cochera: parseInt(row[5]) || null,
          piso_cochera: 'Sótano 1',
          area_cochera: 15.0,
          estado: true,
        };
        cocherasData.push(cochera);

        // Depósito
        const deposito = {
          id_edificio,
          id_empresa,
          numero_deposito: parseInt(row[6]) || null,
          piso_deposito: 'Sótano 1',
          area_deposito: 5.0,
          estado: true,
        };
        depositosData.push(deposito);

        asignacionesData.push({
          id_empresa,
          id_edificio,
          id_departamento: null, // Será actualizado después de guardar departamentos
          id_propietario: null,  // Será actualizado después de guardar propietarios
          id_cochera: null,      // Será actualizado después de guardar cocheras
          id_deposito: null,     // Será actualizado después de guardar depósitos
        });
      }

      // Paso 2: Guardar datos en cada tabla
      const departamentosResponse = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos/insertar`,
        departamentosData,
        { headers: getAuthHeaders() }
      );

      const propietariosResponse = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios/insertar`,
        propietariosData,
        { headers: getAuthHeaders() }
      );

      const cocherasResponse = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cocheras/insertar`,
        cocherasData,
        { headers: getAuthHeaders() }
      );

      const depositosResponse = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/depositos/insertar`,
        depositosData,
        { headers: getAuthHeaders() }
      );

      // Paso 3: Actualizar IDs en las asignaciones
      asignacionesData.forEach((asignacion, index) => {
        asignacion.id_departamento = departamentosResponse.data[index]?.id_departamento || null;
        asignacion.id_propietario = propietariosResponse.data[index]?.id_propietario || null;
        asignacion.id_cochera = cocherasResponse.data[index]?.id_cochera || null;
        asignacion.id_deposito = depositosResponse.data[index]?.id_deposito || null;
      });

      // Validar asignaciones antes de guardar
      const asignacionesConErrores = asignacionesData.filter(
        (asignacion) => !asignacion.id_departamento || !asignacion.id_propietario
      );
      if (asignacionesConErrores.length > 0) {
        console.error('Error: Algunas asignaciones no tienen IDs válidos:', asignacionesConErrores);
        return false;
      }

      // Paso 4: Guardar asignaciones en la tabla asignación
      await registerAsignaciones(asignacionesData);

      // Paso 5: Recargar departamentos
      await fetchDepartamentos();

      return true;
    } catch (error) {
      console.error('Error al guardar asignaciones:', error);
      return false;
    }
  };

  const registerAsignaciones = async (asignaciones) => {
    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignacion/insertar`,
        asignaciones,
        { headers: getAuthHeaders() }
      );

      if (response.status === 201) {
        console.log('Asignaciones registradas correctamente.');
      } else {
        console.error('Error al registrar asignaciones:', response);
      }
    } catch (error) {
      console.error('Error al registrar asignaciones:', error.response?.data || error.message);
    }
  };

  return {
    departamentos,
    setDepartamentos,
    hotTableRefAsignacion,
    saveAsignacion,
    fetchDepartamentos,
  };
};

export default useStep2;
