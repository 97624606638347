import { useState } from 'react';
import axios from 'axios';

const useStep4 = (id_empresa, id_edificio) => {
  const [tipoCuentaSeleccionado, setTipoCuentaSeleccionado] = useState('');
  const [nombreBanco, setNombreBanco] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [numeroCuentaCci, setNumeroCuentaCci] = useState('');

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autorización no encontrado.');
    }
    return { Authorization: `Bearer ${token}` };
  };

  const validateFields = () => {
    if (!tipoCuentaSeleccionado) {
      alert('Por favor, selecciona un tipo de cuenta.');
      return false;
    }
    if (!nombreBanco) {
      alert('Por favor, selecciona un banco.');
      return false;
    }
    if (!numeroCuenta || numeroCuenta.trim().length === 0) {
      alert('Por favor, introduce un número de cuenta válido.');
      return false;
    }
    if (!numeroCuentaCci || numeroCuentaCci.trim().length === 0) {
      alert('Por favor, introduce un número de cuenta CCI válido.');
      return false;
    }
    return true;
  };

  const saveCuentaBancaria = async () => {
    if (!validateFields()) {
      return false;
    }

    if (!id_empresa || !id_edificio) {
      alert('Error: No se ha creado el edificio o empresa. Completa los pasos anteriores.');
      return false;
    }

    const cuentaBancaria = {
      id_empresa,
      id_edificio,
      id_tipo_cuenta: tipoCuentaSeleccionado,
      nombre_banco: nombreBanco,
      numero_cuenta: numeroCuenta,
      numero_cuenta_cci: numeroCuentaCci,
    };

    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuentas_bancarias/insertar`,
        cuentaBancaria,
        { headers: getAuthHeaders() }
      );

      if (response.status === 201) {
        alert('Cuenta bancaria guardada exitosamente.');
        return true;
      } else {
        console.error('Error en la respuesta del servidor:', response);
        alert('Hubo un problema al guardar la cuenta bancaria.');
        return false;
      }
    } catch (error) {
      console.error('Error al guardar la cuenta bancaria:', error);
      if (error.response) {
        alert(`Error del servidor: ${error.response.data.message || 'Consulta con soporte técnico.'}`);
      } else {
        alert('Ocurrió un error al procesar tu solicitud. Revisa tu conexión a internet.');
      }
      return false;
    }
  };

  return {
    tipoCuentaSeleccionado,
    setTipoCuentaSeleccionado,
    nombreBanco,
    setNombreBanco,
    numeroCuenta,
    setNumeroCuenta,
    numeroCuentaCci,
    setNumeroCuentaCci,
    saveCuentaBancaria,
  };
};     
                                                             

export default useStep4;
