import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';


const FormularioCuotaFija = ({ id_empresa, id_edificio, onAddSuccess }) => {
  const [cuotaFija, setCuotaFija] = useState('');
  const [fecha, setFecha] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [cuotasFijas, setCuotasFijas] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);


  // Función para obtener el token del localStorage y configurarlo en los encabezados
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token no encontrado. Por favor, inicia sesión.');
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };


  useEffect(() => {
    fetchDepartamentos();
    fetchCuotasFijas();
  }, [id_empresa, id_edificio]);


  const fetchDepartamentos = async () => {
    try {
      const url = `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`;
      const response = await axios.get(url, getAuthHeaders());
      setDepartamentos(response.data || []); // Garantizar que siempre sea un arreglo
    } catch (error) {
      console.error('Error al obtener los departamentos:', error.response?.data || error.message);
      alert('Error al cargar los departamentos. Verifica tu conexión.');
    }
  };


  const fetchCuotasFijas = async () => {
    try {
      const url = `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_fijas`;
      const response = await axios.get(url, getAuthHeaders());
      setCuotasFijas(response.data || []); // Asegurar que siempre sea un arreglo
    } catch (error) {
      console.error('Error al obtener las cuotas fijas:', error.response?.data || error.message);
      alert('Error al cargar las cuotas fijas. Verifica tu conexión.');
    }
  };


  const validateFields = () => {
    if (!selectedDepartamento) {
      alert('Selecciona un departamento.');
      return false;
    }
    if (!cuotaFija || cuotaFija <= 0) {
      alert('Introduce una cuota fija válida.');
      return false;
    }
    if (!fecha) {
      alert('Selecciona una fecha válida.');
      return false;
    }
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!validateFields()) return;


    const nuevaCuotaFija = [
      {
        id_empresa,
        id_edificio,
        id_departamento: selectedDepartamento,
        cuota_fija: parseFloat(cuotaFija),
        fecha_cuota_fija: fecha,
      },
    ];


    try {
      const url = `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_fijas/insertar`;
      const response = await axios.post(url, nuevaCuotaFija, getAuthHeaders());


      if (response.status === 201) {
        if (onAddSuccess) {
          onAddSuccess();
        }
        setOpenSnackbar(true);
        fetchCuotasFijas(); // Refrescar las cuotas
        setCuotaFija('');
        setFecha('');
        setSelectedDepartamento('');
      }
    } catch (error) {
      console.error('Error al agregar la cuota fija:', error.response?.data || error.message);
      alert('Error al guardar la cuota fija. Verifica tu conexión o intenta nuevamente.');
    }
  };


  return (
    <Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Departamento</InputLabel>
          <Select
            value={selectedDepartamento}
            onChange={(e) => setSelectedDepartamento(e.target.value)}
            label="Departamento"
          >
            {departamentos.map((dep) => (
              <MenuItem key={dep.id_departamento} value={dep.id_departamento}>
                {`Departamento ${dep.numero_departamento}`} {/* Mostrar el número del departamento */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Cuota Fija"
          variant="outlined"
          type="number"
          value={cuotaFija}
          onChange={(e) => setCuotaFija(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Fecha"
          variant="outlined"
          type="date"
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            Agregar Cuota Fija
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Cuota fija ingresada correctamente"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Typography variant="h6" gutterBottom>
        Cuotas Fijas Existentes
      </Typography>
      <List>
        {cuotasFijas.length > 0 ? (
          cuotasFijas.map((cuota) => (
            <ListItem key={cuota.id_cuota_fija}>
              <ListItemText
                primary={`Departamento: ${cuota.id_departamento} - Cuota: S/ ${cuota.cuota_fija}`}
                secondary={`Fecha: ${cuota.fecha_cuota_fija}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No hay cuotas fijas registradas.
          </Typography>
        )}
      </List>
    </Box>
  );
};


export default FormularioCuotaFija;



