import React from 'react';
import { Box, Typography, Grid, Avatar, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { tokens } from '../../theme';

// Ruta externa para la imagen decorativa
const welcomeImage = "https://sofia-imagenes-produccion.s3.us-west-2.amazonaws.com/autenticacion/inicioSesion.jpg";

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Obtener datos del usuario desde localStorage
  const nombre_usuario = localStorage.getItem("nombre_usuario") || "Usuario";
  const rolUsuario = localStorage.getItem("role") || "Administrador";

  // Estilos personalizados
  const Title = styled(Typography)(({ theme }) => ({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: colors.blueAccent[700],
    textAlign: 'center',
    marginBottom: '1rem',
  }));

  const Subtitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    color: colors.grey[700],
    textAlign: 'center',
    marginBottom: '1.5rem',
  }));

  const FeatureList = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: '1rem 0',
    '& li': {
      marginBottom: '0.8rem',
      fontSize: '1rem',
      color: colors.grey[800],
    },
  });

  return (
    <Box p={{ xs: 2, md: 4 }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* Imagen decorativa */}
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <img 
            src={welcomeImage}
            alt="Bienvenida"
            style={{
              width: '100%',
              maxWidth: '380px',
              height: 'auto',
              borderRadius: '8px'
            }}
          />
        </Grid>

        {/* Mensaje de bienvenida */}
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Avatar
              alt={nombre_usuario}
              src={localStorage.getItem("url_imagen_usuario") || ""}
              sx={{ width: 90, height: 90, margin: '0 auto 1rem' }}
            />
            <Title variant="h4">¡Hola, {nombre_usuario}!</Title>
            <Subtitle>Bienvenido a Sofia, tu asistente virtual inteligente</Subtitle>
            <Typography paragraph sx={{ textAlign: 'justify' }}>
              Gracias por elegir nuestro software, especialmente diseñado para facilitar la administración de edificios y condominios. Optimiza tu tiempo y mejora la gestión con estas funcionalidades:
            </Typography>

            {/* Lista de características */}
            <FeatureList>
              <li>✔ Emite recibos de mantenimiento fácilmente.</li>
              <li>✔ Administra ingresos y egresos con precisión.</li>
              <li>✔ Controla el flujo de caja en tiempo real.</li>
              <li>✔ Lleva un registro detallado de deudores.</li>
              <li>✔ Programa mantenimientos usando el calendario.</li>
              <li>✔ Ofrece acceso intuitivo para propietarios.</li>
            </FeatureList>

            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginTop: '1.5rem', padding: '10px 20px', fontSize: '1rem' }}
              onClick={() => console.log('Explorar edificios')}
            >
              Explora tus Edificios
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
