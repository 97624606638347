import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme, Typography, Box, Button, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Big from 'big.js';
import FormularioCuotaFija from "../FormularioCuotaFija/FormularioCuotaFija";


const CuotaMantenimiento = () => {
  const { id_empresa, id_edificio } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [departamentosData, setDepartamentosData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openModal, setOpenModal] = useState(false);
  const [openFormularioCuotaFija, setOpenFormularioCuotaFija] = useState(false);
  const [calculationType, setCalculationType] = useState(null);


  // Función para obtener el token del localStorage y configurarlo en los encabezados
  const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };


  const distribuirMontoPorFrecuencia = (monto, frecuencia) => {
    const meses = 12;
    let distribucion = Array(meses).fill(0);


    switch (frecuencia.toLowerCase()) {
      case 'mensual':
        return distribucion.fill(monto);
      case 'bimensual':
        for (let i = 0; i < meses; i += 2) {
          distribucion[i] = monto;
        }
        return distribucion;
      case 'trimestral':
        for (let i = 0; i < meses; i += 3) {
          distribucion[i] = monto;
        }
        return distribucion;
      case 'semestral':
        distribucion[0] = monto;
        distribucion[6] = monto;
        return distribucion;
      case 'anual':
        distribucion[0] = monto;
        return distribucion;
      default:
        return distribucion;
    }
  };


  const calcularTotalGeneral = (gastos) => {
    let totalGeneral = 0;
    gastos.forEach(gasto => {
      gasto.subconceptos.forEach(subconcepto => {
        totalGeneral += Object.values(subconcepto.valores).reduce((acc, curr) => acc + curr, 0);
      });
    });
    return totalGeneral;
  };


  const calcularGastosMensuales = (gastos) => {
    return calcularTotalGeneral(gastos) / 12;
  };


  useEffect(() => {
    if (calculationType === 'porcentaje') {
      fetchData();
    } else if (calculationType === 'cuotasFijas') {
      fetchCuotasFijas();
    }
  }, [id_empresa, id_edificio, selectedMonth, selectedYear, calculationType]);


  const fetchCuotasFijas = async () => {
    try {
      const [cuotasResponse, departamentosResponse, propietariosResponse, asignacionResponse, porcentajeResponse] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_fijas`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignacion`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/porcentaje_participacion`, getAuthHeaders())
      ]);
 
      // Validar que las respuestas sean arreglos
      const cuotasFijas = Array.isArray(cuotasResponse.data) ? cuotasResponse.data : [];
      const departamentosFiltrados = Array.isArray(departamentosResponse.data) ? departamentosResponse.data : [];
      const propietarios = Array.isArray(propietariosResponse.data) ? propietariosResponse.data : [];
      const asignacionesFiltradas = Array.isArray(asignacionResponse.data) ? asignacionResponse.data : [];
      const porcentajesFiltrados = Array.isArray(porcentajeResponse.data) ? porcentajeResponse.data : [];
 
      // Generar los datos de los departamentos
      const departamentosWithCuotas = departamentosFiltrados.map(dep => {
        const asignacion = asignacionesFiltradas.find(a => a.id_departamento === dep.id_departamento);
        const propietario = asignacion ? propietarios.find(p => p.id_propietario === asignacion.id_propietario) : null;
        const porcentaje = porcentajesFiltrados.find(p => p.id_departamento === dep.id_departamento);
        const cuota = cuotasFijas.find(c => c.id_departamento === dep.id_departamento);
 
        return {
          id: dep.id_departamento,
          numero_departamento: dep.numero_departamento,
          nombre_propietario: propietario ? propietario.nombre_propietario : "N/A",
          apellido_propietario: propietario ? propietario.apellido_propietario : "N/A",
          porcentaje_participacion: porcentaje?.porcentaje_participacion || "N/A",
          monto_cuota: cuota ? cuota.cuota_fija : "N/A",
        };
      });
 
      setDepartamentosData(departamentosWithCuotas);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
 
      // Loguear las respuestas para depurar el problema
      if (error.response) {
        console.error("Datos recibidos:", error.response.data);
      }
    }
  };
 


  const fetchData = async () => {
    try {
      const [asignacionResponse, porcentajeResponse, departamentosResponse, conceptosResponse, subconceptosResponse, propietariosResponse] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignacion`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/porcentaje_participacion`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/conceptos`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/subconceptos`, getAuthHeaders()),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios`, getAuthHeaders())
      ]);
     
      const asignacionesFiltradas = asignacionResponse.data;
      const porcentajesFiltrados = porcentajeResponse.data;
      const departamentosFiltrados = departamentosResponse.data;


      const conceptosData = conceptosResponse.data;
      const subconceptosData = subconceptosResponse.data;


      const gastos = conceptosData.map(concepto => {
        const relatedSubconceptos = subconceptosData.filter(sub => sub.id_concepto === concepto.id_concepto);
        const subconceptos = relatedSubconceptos.map(sub => {
          const distribucion = distribuirMontoPorFrecuencia(sub.monto_subconcepto, sub.frecuencia_cobro);
          return {
            nombre: sub.nombre_subconcepto,
            valores: distribucion
          };
        });
        return {
          concepto: concepto.nombre_concepto,
          subconceptos: subconceptos
        };
      });
     
      const propietarios = propietariosResponse.data;
      const gastoMensual = calcularGastosMensuales(gastos);


      const departamentosWithCuotas = departamentosFiltrados.map(dep => {
        const asignacion = asignacionesFiltradas.find(a => a.id_departamento === dep.id_departamento);
        const propietario = asignacion ? propietarios.find(p => p.id_propietario === asignacion.id_propietario) : null;
        const porcentaje = porcentajesFiltrados.find(p => p.id_departamento === dep.id_departamento);
       
        const porcentajeDecimal = porcentaje ? new Big(porcentaje.porcentaje_participacion || 0).div(100) : new Big(0);
        const montoCuota = asignacion && porcentaje
          ? new Big(gastoMensual).times(porcentajeDecimal).toFixed(2)
          : "0.00";


        return {
          id: dep.id_departamento,
          numero_departamento: dep.numero_departamento,
          nombre_propietario: propietario ? propietario.nombre_propietario : "N/A",
          apellido_propietario: propietario ? propietario.apellido_propietario : "N/A",
          porcentaje_participacion: porcentaje ? porcentaje.porcentaje_participacion : "N/A",
          monto_cuota: montoCuota
        };
      });


      setDepartamentosData(departamentosWithCuotas);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };


  const totalMontoCuota = departamentosData.reduce((acc, curr) => acc + parseFloat(curr.monto_cuota), 0);


  const monthOptions = Array.from({ length: 12 }, (_, index) => {
    const month = new Date(2000, index).toLocaleString("default", { month: "long" });
    return { value: index + 1, label: month };
  });


  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const maxDisplayedYears = 10;
  const yearOptions = Array.from(
    { length: Math.min(maxDisplayedYears, currentYear - startYear + 1) },
    (_, index) => {
      const year = currentYear - index;
      return { value: year, label: year };
    }
  );


  const columns = [
    { field: "numero_departamento", headerName: "Número Departamento", flex: 1 },
    { field: "nombre_propietario", headerName: "Nombre Propietario", flex: 1 },
    { field: "apellido_propietario", headerName: "Apellido Propietario", flex: 1 },
    { field: "porcentaje_participacion", headerName: "Porcentaje de Participación %", flex: 1 },
    { field: "monto_cuota", headerName: "Monto de Cuota", flex: 1 }
  ];


  return (
    <Box m="20px">
      <Typography variant="h2">Cuotas de Mantenimiento</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <Box display="flex" gap="1rem" alignItems="center">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>MES:</InputLabel>
            <Select value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>AÑO:</InputLabel>
            <Select value={selectedYear} onChange={(event) => setSelectedYear(event.target.value)}>
              {yearOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
            style={{ backgroundColor: colors.blueAccent[700] }}
          >
            Escoger tipo de cálculo
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1">
            <strong>Total Monto Cuota:</strong>
            <span style={{
              backgroundColor: colors.blueAccent[700],
              color: theme.palette.primary.contrastText,
              padding: theme.spacing(0.5, 1),
              borderRadius: theme.spacing(0.5),
              fontSize: '1.2em'
            }}>
              {totalMontoCuota.toFixed(2)}
            </span>
          </Typography>
        </Box>
      </Box>


      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Selecciona el tipo de cálculo</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(false);
              setCalculationType('porcentaje');
            }}
            style={{ marginRight: theme.spacing(2) }}
          >
            Cálculo por Porcentaje de Participación
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenModal(false);
              setCalculationType('cuotasFijas');
              setOpenFormularioCuotaFija(true);
            }}
          >
            Ingresar Cuotas
          </Button>
        </DialogContent>
      </Dialog>


      <Dialog
        open={openFormularioCuotaFija}
        onClose={() => setOpenFormularioCuotaFija(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {/* Asegúrate de pasar ambos parámetros id_empresa e id_edificio */}
          <FormularioCuotaFija
            id_empresa={id_empresa}
            id_edificio={id_edificio}
            onAddSuccess={fetchCuotasFijas}
          />
        </DialogContent>
      </Dialog>




      <Box
        m="1rem 0 0 0"
        height="62vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={departamentosData}
            columns={columns}
            pageSize={departamentosData.length}
          />
        </div>
      </Box>
    </Box>
  );
};


export default CuotaMantenimiento;



